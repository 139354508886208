



















import { defineComponent } from '@vue/composition-api'

import PageView from '@/components/templates/h/common/areastore/PageView.vue'
import SettingImportModal from '@/components/templates/h/SettingImportModal.vue'
import {
  AreastoreOutputCondition,
  AreastoreExtractCondition,
} from '@/types/entities'
import {
  TemplateFile,
  ResourceType,
  Where,
  WhereOp,
  OrderBy,
  OrderByDirection,
  OrderByNullsOption,
  VUiPAreastoresCountDocument,
} from '@/types/generated/graphql'
import {
  OUTPUT_COND_DELETE,
  OUTPUT_COND_STORE,
  EXPORT_FILENAME,
  SELECT_MAX_COUNT,
} from '@/utils/constant'
import importExportHelper from '@/utils/importExportHelper'
import { addDayYMD } from '@/utils/mixin'
import { setWhereValue, setWhereValues } from '@/utils/searchConditionDef'
import Toast from '@/utils/toast'
const DEFAULT_OUTPUT_CONST = {
  storeOutput: [OUTPUT_COND_STORE.tanten],
  delOutput: [],
}
const DEFAULT_EXTRACT_CONST = {
  validDate: [addDayYMD(1), '2999-12-31'],
}
export default defineComponent({
  components: { PageView, SettingImportModal },
  layout: 'sidebar',
  created() {
    this.helper = new importExportHelper(this)
  },
  data() {
    return {
      defaultDateValue: DEFAULT_EXTRACT_CONST.validDate,
      resourceType: ResourceType.UiPAreastore,
      templateFile: TemplateFile.UiPAreastore_01,
      extractCondition: {
        validDate: DEFAULT_EXTRACT_CONST.validDate,
        /** 参照元店舗 */
        stores: [],
        /** 参照商品 */
        areas: [],
      },
      outputCondition: DEFAULT_OUTPUT_CONST,
      outputUseList: [
        {
          outputCondType: 'Store',
          displayItems: DEFAULT_OUTPUT_CONST.storeOutput,
          defaultSelected: DEFAULT_OUTPUT_CONST.storeOutput,
          disabledItems: DEFAULT_OUTPUT_CONST.storeOutput,
        },
        {
          outputCondType: 'Delete',
          displayItems: [OUTPUT_COND_DELETE.includeDelete],
          defaultSelected: DEFAULT_OUTPUT_CONST.delOutput,
          disabledItems: [],
        },
      ],
      totalCount: null,
      exportSpinner: false,
    }
  },
  methods: {
    async changeExtractCondition(cond: AreastoreExtractCondition) {
      console.log('changeExtractCondition', cond)
      this.extractCondition = cond
      this.requestCount()
    },
    changeOutputCondition(cond: AreastoreOutputCondition) {
      this.outputCondition = cond
      this.requestCount()
    },
    async requestCount() {
      this.totalCount = null
      this.exportSpinner = true
      if (this.checkRequired(false)) {
        try {
          const result = await this.$apollo.query({
            query: VUiPAreastoresCountDocument,
            variables: { where: this._createWhere() },
          })
          const data = result.data.vUiPAreastores.totalCount
          this.totalCount = data
          this.totalCount = data.toString()
          this.exportSpinner = false
        } catch (e) {
          console.error(e)
          this.exportSpinner = false
          this.totalCount = '-'
        }
      } else {
        this.exportSpinner = false
        this.totalCount = '-'
      }
    },
    //　入力チェック
    checkRequired(showToast: boolean) {
      // 入力チェック開始
      let errMsg = []
      // 有効日
      if (
        !this.extractCondition.validDate[0] ||
        !this.extractCondition.validDate[1]
      ) {
        errMsg.push({
          message: this.$i18n.t('error.requiredItemNotInput'),
        })
      }
      // 選択数上限チェック
      if (this.extractCondition.areasCount > SELECT_MAX_COUNT) {
        errMsg.push({
          message: this.$i18n.t('error.itemInputIsTooMany', {
            item: this.$i18n.t('label.areaStore'),
            selected: this.extractCondition.areasCount,
            max: SELECT_MAX_COUNT,
          }),
        })
      }
      if (this.extractCondition.storesCount > SELECT_MAX_COUNT) {
        errMsg.push({
          message: this.$i18n.t('error.itemInputIsTooMany', {
            item: this.$i18n.t('label.store'),
            selected: this.extractCondition.storesCount,
            max: SELECT_MAX_COUNT,
          }),
        })
      }
      if (errMsg.length) {
        if (showToast) {
          for (const msg of errMsg) {
            Toast.error(this, msg)
          }
        }
        // 入力チェックに引っかかった場合はエクスポートの要求を出さない
        return false
      }
      return true
    },
    /*********************************
     * 以下はインポートリクエスト用の処理
     *********************************/
    requestImport() {
      console.log('requestImport')
      this.$refs.settingImportModal.show()
    },
    /**
     * インポート開始処理
     */
    async importFileUpload(files: File[]) {
      console.log('importFileUpload', { files })
      this.helper.import({ files: files, resourceType: this.resourceType })
    },
    /**
     * エクスポート処理
     */
    async requestExport() {
      if (this.checkRequired(true)) {
        const query = this._createQueryString()
        await this.helper.export({
          query,
          jmespathQuery:
            'data.vUiPAreastores.edges[].node | map(&merge(`{"__update": null}`, @), @)',
          templateFile: this.templateFile,
          filenameExport: EXPORT_FILENAME.areastore,
        })
      }
    },
    /**
     * ファイル抽出Queryを構築する
     */
    _createQueryString(): string {
      const where = this._createWhere()
      const orderBy = this._createOrderByStatement()
      const rowQuery = `
        query c {
          vUiPAreastores(
            where: ${this.helper.generateQueryWhereString(where)}
            orderBy: ${this.helper.generateQueryOrderByString(orderBy)}
          ) {
            edges {
              node {
                inactiveFlg
                areaCd
                areaCdName
                storeCd
                storeCdName
                startDate
                endDate
                areaType
                configuredFlg
                lastModifiedDatetime
                lastModifiedUserName
              }
            }
          }
        }
        `
      return this.helper.generateQueryString({
        rowQuery: rowQuery,
        resourceType: this.resourceType,
        where: where,
      })
    },
    /**
     * ファイル抽出QueryのOrderByパラメータを構築する
     */
    _createOrderByStatement(): OrderBy[] {
      return [
        {
          field: 'areaCd',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
        {
          field: 'storeCd',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
        {
          field: 'startDate',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
        {
          field: 'endDate',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
      ]
    },
    /**
     * ファイル抽出QueryのWhereパラメータを構築する
     */
    _createWhere() {
      const ITEM_SEARCH_CONDITION: Where[] = [
        // 有効日
        { field: ['startDate'], op: WhereOp.Le, value: [''] },
        { field: ['endDateNvl'], op: WhereOp.Ge, value: [''] },
        // 棚パターンの抽出条件
        {
          field: ['areaCd'],
          op: WhereOp.In,
          value: [''],
        },
        // 店舗の抽出条件
        {
          field: ['storeCd'],
          op: WhereOp.In,
          value: [''],
        },
        // 出力条件
        { field: ['inactiveFlg'], op: WhereOp.In, value: [''] },
      ]
      const where = JSON.parse(JSON.stringify(ITEM_SEARCH_CONDITION))
      setWhereValue(where, 'startDate', [this.extractCondition.validDate[1]])
      setWhereValue(where, 'endDateNvl', [this.extractCondition.validDate[0]])
      setWhereValue(where, 'areaCd', this.extractCondition.areas)
      setWhereValue(where, 'storeCd', this.extractCondition.stores)

      setWhereValue(where, 'inactiveFlg', [
        '0', // 削除されていないデータは必ず出力する
        ...this.outputCondition.delOutput,
      ])
      console.log({ where })
      return where
    },
  },
})
