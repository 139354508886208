






















import {
  defineComponent,
  PropType,
  ref,
  watch,
  computed,
} from '@vue/composition-api'

import HLine from '@/components/atoms/HLine.vue'
import DateGroup from '@/components/organisms/h/item/date/DateGroup.vue'
import ItemHierarchyGroup from '@/components/organisms/h/item/hierarchy/ItemHierarchyGroup.vue'
import ExtractConditionLayout from '@/components/organisms/h/layout/ExtractConditionLayout.vue'
import {
  AreastoreExtractCondition,
  dateGroupExtractCondition,
  itemHierarchyGroupExtractCondition,
  itemHierarchyUseList,
  dateRange,
} from '@/types/entities'

export default defineComponent({
  components: {
    ExtractConditionLayout,
    DateGroup,
    ItemHierarchyGroup,
    HLine,
  },
  props: {
    defaultValue: {
      type: Array as PropType<string[]>,
      default: () => ['', ''],
    },
  },
  setup(props, context) {
    /*********************************
     * 表示する選択肢
     *********************************/
    const itemStoreUseList = ref<itemHierarchyUseList>([
      ['StoreShelve', 'Store'],
    ])
    /*********************************
     * データ
     *********************************/
    const extractConditionOfDate = ref<dateGroupExtractCondition>({})
    const extractConditionOfItemStore = ref<itemHierarchyGroupExtractCondition>(
      {}
    )

    /*********************************
     * emitデータの作成
     *********************************/
    // いずれかの抽出条件変更時の処理
    const whereValidData = ref<string[]>(props.defaultValue)

    const changeExtractCondition = () => {
      const base: AreastoreExtractCondition = {
        validDate:
          extractConditionOfDate.value.dateRange ||
          (props.defaultValue as dateRange),
        areas: extractConditionOfItemStore.value.storeShelve || [],
        areasCount: extractConditionOfItemStore.value.storeShelveCount || 0,
        stores: extractConditionOfItemStore.value.store || [],
        storesCount: extractConditionOfItemStore.value.storeCount || 0,
      }
      let cond = { ...base }
      console.log('changeExtractCondition', { cond })
      context.emit('change-extract-condition', cond)
      whereValidData.value =
        extractConditionOfDate.value.dateRange || props.defaultValue
    }

    /*********************************
     * 以下は各抽出条件変更時の処理
     *********************************/
    const changeExtractConditionOfDate = (
      params: dateGroupExtractCondition
    ) => {
      extractConditionOfDate.value = params
      changeExtractCondition()
    }
    const changeExtractConditionOfItemStore = (
      params: itemHierarchyGroupExtractCondition
    ) => {
      extractConditionOfItemStore.value = params
      changeExtractCondition()
    }

    /*********************************
     * ラジオボタン変更時の処理
     *********************************/
    const changeRadio = (selected: string) => {
      selectedRadio.value = selected
      changeExtractCondition()
    }
    const selectedRadio = ref<string>('')
    const disabledByRadio = (name: string) => {
      return name !== selectedRadio.value
    }
    const defaultDateValue = props.defaultValue
    const requiredItemInput = ['有効日：常に入力']

    return {
      requiredItemInput,
      itemStoreUseList,
      defaultDateValue,
      whereValidData,
      changeExtractConditionOfDate,
      changeExtractConditionOfItemStore,
      changeRadio,
      disabledByRadio,
    }
  },
})
